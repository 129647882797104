/**
 * Module dependencies`.
 */

import { MenuCategory, useCategories } from './menu';
import { useMemo } from 'react';

/**
 * Export `worthingtonLinks`.
 */

export const worthingtonLinks: MenuCategory = {
  options: [
    {
      href: 'https://www.worthingtonindustries.eu/company/',
      label: 'menu:worthingtonEurope'
    },
    {
      href: 'https://www.worthingtonenterprises.com/',
      label: 'menu:worthingtonUsa'
    }
  ],
  title: 'footer:labels.links'
};

/**
 * Export `socialNetworks`.
 */

export const socialNetworks: MenuCategory = {
  options: [
    {
      href: 'https://www.linkedin.com/company/amtrol-alfa',
      label: 'Linkedin'
    },
    {
      href: 'https://www.facebook.com/Amtrol-Alfa-816325408419928/',
      label: 'Facebook'
    },
    {
      href: 'https://twitter.com/AmtrolAlfa',
      label: 'Twitter'
    },
    {
      href: 'https://www.instagram.com/amtrolalfa/',
      label: 'Instagram'
    },
    {
      href: 'https://www.youtube.com/user/AmtrolAlfa',
      label: 'Youtube'
    }
  ],
  title: 'footer:labels.socialNetworks'
};

/**
 * Export `portugal2020Link`.
 */

export const portugal2020Link = 'https://amtrol-alfa.com/support-programs/';

/**
 * Export `useFooter`.
 */

export function useFooter() {
  const { markets } = useCategories();

  return useMemo(() => {
    return [worthingtonLinks, markets, socialNetworks];
  }, [markets]);
}
